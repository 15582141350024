import React from 'react';
import { Container, Segment, Header, Button, List, Icon, Label, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentShifts, selectShiftsLoading, selectShiftsErrors } from '../../redux/shifts/shifts.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';

import { selectSurveysLoading, selectCurrentSurveys, selectSurveysErrors } from '../../redux/surveys/surveys.selectors';
import { toggleSurveyStart, getSurveysStart, manualSurveyStart } from '../../redux/surveys/surveys.actions';
import WorkdaysList from '../WorkdaysList/WorkdaysList.component';

class SurveyList extends React.Component {

  componentDidMount() {
    const { currentCompany, getShiftsStart, getSurveysStart } = this.props;
    getShiftsStart({ currentCompany });
    getSurveysStart();
  }

  buildTemplateShiftKey = (templateId, shiftId) => {
    return `${templateId}--${shiftId}`;
  }

  toggleScheduledSurvey = (templateId, shiftId, nextStatus) => {
    const { toggleSurveyStart } = this.props;
    toggleSurveyStart({ templateId, shiftId, nextStatus });
  }

  renderShiftSublist = (shiftList) => {
    const { surveysLoading, currentSurveys, manualSurveyStart } = this.props;
    const shiftIds = Object.keys(shiftList);
    const templateId = "covid-19-survey";
    return (
      <List divided relaxed verticalAlign='middle' animated size="large">
        { shiftIds.map(shiftId => {
          const templateShiftKey = this.buildTemplateShiftKey(templateId, shiftId); 
          let isScheduled = false;
          if (!!currentSurveys) {
            if (Object.keys(currentSurveys).includes(templateShiftKey)) {
              isScheduled = currentSurveys[templateShiftKey].isEnabled;
            }
          }
          return (
            <List.Item key={shiftId}>
              <List.Content floated='right'>
              { surveysLoading ? (<Icon loading name='spinner' />) : (
                <React.Fragment>
                <Button 
                  onClick={() => this.toggleScheduledSurvey(templateId, shiftId, !isScheduled)}
                  content={isScheduled ? "Disable" : "Enable"}
                  color={isScheduled ? "green" : "blue"}
                  icon={isScheduled ? "check" : "plus"}
                  labelPosition="left"
                  style={{ float: 'right' }}
                />
                { isScheduled ? (
                  <Button 
                    onClick={() => manualSurveyStart(templateShiftKey)}
                    style={{ float: 'right', clear: 'both', marginTop: '3px' }}
                    content={"Manual Survey"}
                    size="mini"
                    compact
                    // color={isScheduled ? "green" : "blue"}
                    disabled={!shiftList[shiftId].assignedEmployees.length}
                    icon="send"
                    labelPosition="left"
                  />
                ) : null}
                </React.Fragment>
              )}
              </List.Content>
              <List.Icon name="tasks" size="large"/>
              <List.Content>
                <List.Header>
                  {shiftList[shiftId].shiftName}
                  <WorkdaysList workdaysSelected={shiftList[shiftId].workdaysSelected} />
                </List.Header>
                <List.Description>
                  <div className="shiftDescriptionWrapper">
                    <Label size="mini" style={{ marginLeft: '3px' }} image basic>
                      <Icon name='stopwatch' />
                      Starts
                      <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                    </Label>
                    { shiftList[shiftId].shiftSendAuto ? (
                      <Label size="mini" color={isScheduled ? "green" : null} style={{ marginLeft: '3px' }} image basic={isScheduled ? false : true}>
                        <Icon name='send' />
                        Autosend
                        <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).subtract(+shiftList[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                      </Label>
                  ) : null }
                  </div>
                </List.Description>
                <List.Description>{`${shiftList[shiftId].assignedEmployees.length || "No"} assigned employees`}</List.Description>
              </List.Content>
            </List.Item>
          )
        }) }
      </List>
    );
  }

  renderShiftList = () => {
    const { currentShifts } = this.props;
    if (!currentShifts) {
      return null;
    } else {
      const currentShiftIds = Object.keys(currentShifts);
      return (
        <React.Fragment>
        {currentShiftIds.length ? (
          this.renderShiftSublist(currentShifts)
        ) : null}
        </React.Fragment>
      );
    }
  }

  render() {
    const { shiftsLoading, shiftsErrors, surveysErrors } = this.props;

    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Surveys</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Schedule Surveys</Header.Content>
            </Header>
          </Segment>

          <Segment loading={shiftsLoading}>
            <Header>
              <Header.Content>COVID-19 Self-Screening SMS Survey <Label color="black">Automated</Label></Header.Content>
              <Header.Subheader>Enable automated surveys for each shift as needed</Header.Subheader>
            </Header>
            {this.renderShiftList()}
          </Segment>

          {/* <Segment secondary>
            <Button color="green" 
              content={
                <span>Save Changes
                <Label circular color="red" className="buttonCircleNotification">1</Label>
                </span>
              } 
              onClick={() => initiateManualSurvey()}
              labelPosition="left" icon="check" 
            />
          </Segment> */}
        </Segment.Group>

        {
          shiftsErrors ? (
            <Message error>
              <Message.Header>Could not load shifts.</Message.Header>
              <Message.Content>Refresh, or contact hello@SalusSMS.com for support.</Message.Content>
            </Message>
          ) : null
        }

        {
          surveysErrors ? (
            <Message error>
              <Message.Header>Could not load surveys.</Message.Header>
              <Message.Content>Refresh, or contact hello@SalusSMS.com for support.</Message.Content>
            </Message>
          ) : null
        }
        
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsErrors: selectShiftsErrors,
  shiftsLoading: selectShiftsLoading,
  surveysLoading: selectSurveysLoading,
  currentSurveys: selectCurrentSurveys,
  surveysErrors: selectSurveysErrors
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany })),
  toggleSurveyStart: ({ templateId, shiftId, nextStatus }) => dispatch(toggleSurveyStart({ templateId, shiftId, nextStatus })),
  getSurveysStart: () => dispatch(getSurveysStart()),
  manualSurveyStart: (surveyId) => dispatch(manualSurveyStart(surveyId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyList);